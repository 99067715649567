@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');
body {
  margin: 0;
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EDF5FF !important;
}

code {
  font-family: 'Lato', sans-serif !important;
}
a{
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #1400FF !important;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif !important;
}
.register_tabs_section button{
  font-size: 10px;
  color: #575757;
  padding: 5px 10px;
  font-weight: 400;
}
.register_tabs_section button[aria-selected="true"]{
  background-color: #0A83FF;
  color: #fff;
}
.body_section{
  padding: 20px 50px;
}
.table_section .chakra-table__container{
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
}
.table_section .chakra-table__container .chakra-table{
  width: 100%;
  
  border-spacing: 0px;
  
}
.table_section .chakra-table__container .chakra-table thead{
  border-spacing: 0px;
}
.table_section .chakra-table__container .chakra-table thead tr th{
  border-spacing: 0px;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif !important;
  color: #000;
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ccc;

}
.table_section .chakra-table__container .chakra-table tbody tr td{
  border-spacing: 0px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif !important;
  color: #000;
  padding: 20px 15px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}
.table_section .chakra-table__container .chakra-table tbody tr:last-child td{
  border-bottom: 0px;
}
.table_section .chakra-table__container .chakra-table tbody tr td a{
  color: #1400FF;
  text-decoration: none;
}
.inner_table_section .chakra-table__container{
  width: 100%;
}
.inner_table_section .chakra-table__container .chakra-table{
  width: 100%;
  border-top: 1px solid #ccc;
  border-spacing: 0px;
}
.inner_table_section .chakra-table__container .chakra-table thead{
  background-color: transparent;
  border-spacing: 0px;
}
.inner_table_section .chakra-table__container .chakra-table thead tr th{
  border-spacing: 0px;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Lato', sans-serif !important;
  color: #585858CC;
  padding: 10px 20px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}
.inner_table_section .chakra-table__container .chakra-table tbody tr td{
  border-spacing: 0px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Lato', sans-serif !important;
  color: #000;
  padding: 10px 20px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}
.inner_table_section .chakra-table__container .chakra-table tbody tr:last-child td{
  border-bottom: 0px;
}
.inner_table_section .chakra-table__container .chakra-table tbody tr td a{
  color: #1400FF;
  text-decoration: none;
}

/*Login Css*/

.field_section{
  margin-bottom: 20px;
}
.field_section .chakra-form__label{
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-bottom: 5px;
  font-family: 'Montserrat', sans-serif !important;
  margin-right: 0px;
}
.field_section label{
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-bottom: 5px;
  display: block;
  font-family: 'Montserrat', sans-serif !important;
  margin-right: 0px;
}
.field_section .chakra-input{
  border: 1px solid #ccc;
  height: 43px;
  padding: 10px 15px;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
}
.field_section .chakra-select{
  border: 1px solid #ccc;
  height: unset;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
}
.setting_page .field_section .chakra-textarea{
  height: 150px;
}

/*Login Css*/

/*Header Css*/

.header_link{
  color: #fff !important;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}
.header_link:hover, .header_link:active{
  font-weight: 700;
}
.forget_link{
  font-size: 10px;
  font-weight: 400;
  color: #0A83FF;
  margin: 10px auto;
  display: block;
  text-align: center;
}

/*Header Css*/

/*Customer Page Css*/

.add_license_link{
  color: #fff !important;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 800;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  cursor: pointer;
}
.add_license_link svg{
  margin-right: 10px;
}
.left_section{
  width: 28%;
}
.right_section{
  width: 70%;
}

/*Customer Page Css*/

/*setting page Css*/


.setting_page{
  width: 1150px;
  margin: 30px auto;
}
.setting_nav_link{
  font-size: 14px;
  font-weight: 500;
  color: #000 !important;
  font-family: 'Montserrat', sans-serif;
  padding: 5px 15px 5px 15px;
  display: block;
  display: flex;
  align-items: center;
}
.setting_nav_link:hover, .setting_nav_link.active{
  background-color: #EDF5FF;
}
.setting_nav_link:hover p, .setting_nav_link.active p{
  color: #0A83FF;
}
.setting_nav_link .icon_img{
  display: block;
}
.setting_nav_link .icon_active_img{
  display: none;
}
.setting_nav_link:hover .icon_img, .setting_nav_link.active .icon_img{
  display: none;
}
.setting_nav_link:hover .icon_active_img, .setting_nav_link.active .icon_active_img{
  display: block;
}
.dropdown_link{
  color: #fff !important;
  font-weight: 700;
  background-color: #504F56 !important;
  display: block;
}
.pagination {
  padding-top: 30px;
  margin: 0px;
  list-style-type: none;
}
.pagination li{
  display: inline-block;
}
.pagination li a{
  padding: 5px 20px;
  display: block;
  border: 1px solid #ccc;
  color: #000 !important;
}
.pagination li a:hover{
  color: #1400FF !important;
}
.pagination li:first-child a{
  border-radius: 5px 0px 0px 5px;
}
.pagination li:last-child a{
  border-radius: 0px 5px 5px 0px;
}

/*Setting Page css*/

@media screen and (max-width: 1920px){
  .left_section{
    width: 20%;
  }
  .right_section{
    width: 78%;
  }
}

@media screen and (max-width: 1680px){
  .left_section{
    width: 22%;
  }
  .right_section{
    width: 76%;
  }
}

@media screen and (max-width: 1440px){
  .left_section{
    width: 24%;
  }
  .right_section{
    width: 74%;
  }
}

@media screen and (max-width: 1366px){
  .left_section{
    width: 26%;
  }
  .right_section{
    width: 72%;
  }
}

@media screen and (max-width: 1280px){
  .left_section{
    width: 28%;
  }
  .right_section{
    width: 70%;
  }
}
